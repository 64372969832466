export default defineAppConfig({
  myTheme: {
    name: 'Website',
  },
  locales: ['vi', 'en'],
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myTheme?: {
      /** Project name */
      name?: string
    }
  }
}
