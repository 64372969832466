export default async function (resType: string = 'host') {
  const nuxtApp = useNuxtApp()

  let host: any = ''

  // for 3.0.0.rc_vercions: host = nuxtApp.ssrContext.req.headers.host
  // UPD 27.01.23:

  if (import.meta.client)
    host = window.location.host
  else
    host = nuxtApp.ssrContext?.event.node.req.headers.host

  if (resType === 'host')
    return host ? _split(host, ':')?.[0] : ''
  else if (resType === 'port')
    return host ? _split(host, ':')?.[1] : ''
  else if (resType === 'hostport')
    return host ? _split(host, ':') : []
  else
    return host ?? ''
}
