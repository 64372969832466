import revive_payload_client_e6kVcmIz1A from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7W5Y5RQxqQ from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qfoUPOE2M9 from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_B15X7CdNDR from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hHA558aHMY from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_o0LtXzN3Th from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TvjEAkOp23 from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "D:/dev_web/.nuxt/components.plugin.mjs";
import prefetch_client_4ymSxmomUE from "D:/dev_web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__35ky6rjzp2erxsdxtyxewyfv7i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_MDOQuxrcjD from "D:/dev_web/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_znBG2SFl75 from "D:/dev_web/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2AmMU1PY54 from "D:/dev_web/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_gH7tJLTZAZ from "D:/dev_web/node_modules/.pnpm/@nuxt+ui@2.18.6_magicast@0.3.5_qrcode@1.5.4_rollup@4.22.5_vite@5.4.8_@types+node@22.7.4_terse_dxs6jwoqi7gccx33quuvklsvhe/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_4denHDvveb from "D:/dev_web/node_modules/.pnpm/@nuxt+ui@2.18.6_magicast@0.3.5_qrcode@1.5.4_rollup@4.22.5_vite@5.4.8_@types+node@22.7.4_terse_dxs6jwoqi7gccx33quuvklsvhe/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_vEvXbUZ00w from "D:/dev_web/node_modules/.pnpm/@nuxt+ui@2.18.6_magicast@0.3.5_qrcode@1.5.4_rollup@4.22.5_vite@5.4.8_@types+node@22.7.4_terse_dxs6jwoqi7gccx33quuvklsvhe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_3F20anMaiK from "D:/dev_web/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_9uhT0UHXTQ from "D:/dev_web/node_modules/.pnpm/@nuxt+icon@1.5.2_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@types+node@22.7.4_terser@5.34.1__vue@3.5.10_typescript@5.6.2_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import vue_date_picker_AkrrI4qPP9 from "D:/dev_web/plugins/vue-date-picker.ts";
export default [
  revive_payload_client_e6kVcmIz1A,
  unhead_7W5Y5RQxqQ,
  router_qfoUPOE2M9,
  payload_client_B15X7CdNDR,
  navigation_repaint_client_hHA558aHMY,
  check_outdated_build_client_o0LtXzN3Th,
  chunk_reload_client_TvjEAkOp23,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4ymSxmomUE,
  plugin_client_MDOQuxrcjD,
  switch_locale_path_ssr_znBG2SFl75,
  i18n_2AmMU1PY54,
  slideovers_gH7tJLTZAZ,
  modals_4denHDvveb,
  colors_vEvXbUZ00w,
  plugin_client_3F20anMaiK,
  plugin_9uhT0UHXTQ,
  vue_date_picker_AkrrI4qPP9
]